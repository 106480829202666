import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const BaylorLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Khera"
    institution="Baylor"
    referralCode="BAYLOR"
    physicianURL="https://www.bcm.edu/people-search/mohit-khera-24469"
  />
)

export default BaylorLandingPage
